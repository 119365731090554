<template>
  <AppPage :show-menu="true" :class="{centered: true, timeoutComplete}" :showFooter="false">
    <div class="container">

      <div class="section" v-if="!timeoutComplete">
        <h1>
          <TheStoryFolderLogo class="stack" />
        </h1>
        <h2>
          Downloading
          <ProgressSpinner />
        </h2>
      </div>
      <div>
        <p v-if="timeoutComplete">
          <small>
            If your download doesn't start<br />automatically <a :href="primary ? primary.link : null">click here</a>.
          </small>
        </p>
        <AppDownloadLinks
          :auto-download="!timeoutComplete"
          :show-secondary="false"
          :show-details="false"
        />
      </div>
      <div v-if="timeoutComplete && $root.os === 'mac'">
        <br />
        <HorizontalRule />
        <br />
        <h2>Can't open the app?</h2>
        <p>
          If you see this notice when opening the app just click the "Open" button.
        </p>
        <img src="/img/mac-open-notice.jpg" alt="macOS Open" class="screenshot" width="300"/>
      </div>
    </div>
  </AppPage>
</template>
<script lang="ts">
import Vue from 'vue';
import AppPage from "@/components/organisms/AppPage.vue";
import TheStoryFolderLogo from "@/components/molecules/TheStoryFolderLogo.vue";
import AppDownloadLinks from "@/components/molecules/AppDownloadLinks.vue";
import ProgressSpinner from "@/components/atoms/ProgressSpinner.vue";
import HorizontalRule from "@/components/atoms/HorizontalRule.vue";

export default Vue.extend({
  components: {
    HorizontalRule,
    ProgressSpinner,
    AppDownloadLinks,
    TheStoryFolderLogo,
    AppPage,
  },

  // BEFORE PUSHING, SET TIMEOUTCOMPLETE TO FALSE AND AUTODOWNLOAD TO TRUE
  data(){
    return {
      timeoutComplete: false
    }
  },
  computed: {
    primary(){
      return this.$store.getters['release/primary']
    },
  },
  created(){
    setTimeout(() => {
      this.timeoutComplete = true;
    }, 6000);
  }
});
</script>
<style scoped lang="less">
@import '../less/variables';
@import '../less/mixins';
.centered{
  text-align: center;
}
.container{
  background: fade(black, 10%);
  padding: 2em;
  border-radius: 1em;
}
.section {
  min-width: 20em;
}
/deep/ .page-content{
  max-width: 630px;
  margin: 0 auto;
}
h2{
  margin-top: 0.5em;
  font-size: 1.5em;
}
.platforms-container{
  font-size: 70%;
}
.progress-spinner{
  margin-right: 0.5em;
  transition: margin 250ms;
}
.fallback{
  display: none;
  transition: margin 500ms;
}
.timeoutComplete{
  .progress-spinner{
    opacity: 0;
    margin-right: -0.5em;
  }
  .platforms-container{
    opacity: 1;
  }
  .fallback{
    opacity: 1;
  }
}
.screenshot{
  border:1px solid @grayDark;
  border-radius: 0.75em;
}
.horizontal-rule{
  margin: 1em 0;
}

</style>
